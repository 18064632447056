import { createEffect, createMemo, createSignal, Match, Switch } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Button, Errors, FieldDescription, Form, Input, Label, TextField } from '@troon/ui';
import { useSubmission } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { gql, mutationAction, useMutation } from '../../../../graphql';
import type { SubscriptionFragment, TroonCardSubscriptionIntent } from '../../../../graphql';
import type { ParentProps } from 'solid-js';

type BaseProps = { subscription: SubscriptionFragment };
type NoPromo = BaseProps & {
	onReceivePromoSubscription?: never;
	productId?: never;
};

type Promo = BaseProps & {
	onReceivePromoSubscription: (subscription: TroonCardSubscriptionIntent) => void;
	productId: string;
};
type Props = Promo | NoPromo;

export function Receipt(props: Props) {
	const applyPromoCode = useMutation(applyPromoCodeAction);
	const promoCodeSubmission = useSubmission(applyPromoCode);
	const [showCodeInput, setShowCodeInput] = createSignal(false);

	const code = createMemo(() => {
		return promoCodeSubmission.input && promoCodeSubmission.result && !promoCodeSubmission.result?.error
			? (((promoCodeSubmission.input[0] as FormData).get('code') as string)?.toUpperCase() ?? undefined)
			: undefined;
	});

	createEffect(() => {
		const sub = promoCodeSubmission.result?.data?.cardSubscription;
		if (props.onReceivePromoSubscription && sub && !promoCodeSubmission.result?.error) {
			props.onReceivePromoSubscription(sub as TroonCardSubscriptionIntent);
		}
	});

	return (
		<>
			<Switch>
				<Match when={props.onReceivePromoSubscription && !code() && !showCodeInput()}>
					<div>
						<Button
							size="sm"
							appearance="transparent"
							class="-ms-2 font-normal normal-case"
							onClick={() => setShowCodeInput(true)}
						>
							Have a promo code?
						</Button>
					</div>
				</Match>
				<Match when={!code() && props.onReceivePromoSubscription}>
					<Form action={applyPromoCode} document={applyPromoCodeToTroonCardSubscription}>
						<input type="hidden" name="productId" value={props.productId} />
						<TextField name="code">
							<Label suppressRequired class="sr-only">
								Promo code
							</Label>
							<Input
								autofocus
								class="uppercase"
								placeholder="CODE"
								suffixElement={
									<Button class="pointer-events-auto normal-case" type="submit" appearance="transparent" size="sm">
										Apply
									</Button>
								}
							/>
							<FieldDescription>Enter your promo code and hit “Apply”.</FieldDescription>
						</TextField>

						<Errors />
					</Form>
				</Match>
			</Switch>
			<div role="table">
				<div role="rowgroup" class="flex flex-col gap-4">
					<Row>
						<RowHead>Subtotal</RowHead>
						<Cell>{props.subscription.subtotalAmount.displayValue}</Cell>
					</Row>
					<Switch>
						<Match when={code() && parseFloat(props.subscription.discountAmount.value)}>
							<Row>
								<RowHead>Promo code ({code()})</RowHead>
								<Cell>
									<span class="inline-flex items-center gap-2 text-green-500">
										<Icon name="tag" />
										{props.subscription.discountAmount.displayValue}
									</span>
								</Cell>
							</Row>
						</Match>
						<Match when={!code() && parseFloat(props.subscription.discountAmount.value)}>
							<Row>
								<RowHead>Discount</RowHead>
								<Cell>
									<span class="inline-flex items-center gap-2 text-green-500">
										<Icon name="tag" />
										{props.subscription.discountAmount.displayValue}
									</span>
								</Cell>
							</Row>
						</Match>
					</Switch>
					<Row>
						<RowHead>Tax</RowHead>
						<Cell>{props.subscription.taxAmount.displayValue}</Cell>
					</Row>
					<Row class="font-semibold">
						<RowHead>Total</RowHead>
						<Cell>{props.subscription.totalAmount.displayValue}</Cell>
					</Row>
				</div>
			</div>
		</>
	);
}

function Row(props: ParentProps<{ class?: string }>) {
	return (
		<div role="row" class={twJoin('flex justify-between gap-6', props.class)}>
			{props.children}
		</div>
	);
}

function RowHead(props: ParentProps<{ class?: string }>) {
	return (
		<div class={twJoin('text-start', props.class)} role="rowheader">
			{props.children}
		</div>
	);
}

function Cell(props: ParentProps) {
	return (
		<div role="cell" class="text-end">
			{props.children}
		</div>
	);
}

const applyPromoCodeToTroonCardSubscription = gql(`mutation applyPromoCode($productId: String!, $code: String!) {
  cardSubscription: setupTroonCardSubscription(troonCardSubscriptionProductId: $productId, promoCode: $code) {
    ...Subscription
  }
}`);

const applyPromoCodeAction = mutationAction(applyPromoCodeToTroonCardSubscription, {
	track: { event: 'applyPromoCode' },
	transform: (data) => ({ code: (data.get('code') as string).toUpperCase() }),
	retry: {
		retryIf: (res) => {
			return !!res.error;
		},
		maxAttempts: 3,
	},
});
